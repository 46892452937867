import ServiceNav from "./ServiceNav";

const Nav = () => {
  return (
    <div className="flex h-[85px] w-full items-center justify-end bg-neutral-400 px-8">
      <ServiceNav/>
    </div>
  );
};

export default Nav;
