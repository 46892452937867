import React, { memo } from "react";
import setComponentSize from "utils/setComponentSize";
import { Size } from "../../types/Style";

type ButtonColor =
  | "primary-contained"
  | "primary-outlined"
  | "primary-text"
  | "danger-contained"
  | "danger-outlined"
  | "danger-text"
  | "warning-contained"
  | "warning-outlined"
  | "warning-text"
  | "secondary-contained"
  | "neutral-text";

type ButtonType = {
  children: React.ReactNode;
  type?: "button" | "reset" | "submit";
  size?: Size | "xs";
  addClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  appendIcon?: React.ReactNode;
  prependIcon?: React.ReactNode;
  widthFull?: boolean;
  color?: ButtonColor;
  cusomRounded?: string;
};

function Button({
  children,
  type = "button",
  color = "primary-contained",
  size = "sm",
  addClassName,
  onClick,
  disabled,
  appendIcon,
  prependIcon,
  widthFull = false,
  cusomRounded = "rounded-full",
}: ButtonType) {
  const splitColor = color.split("-")[0];
  const splitVariant = color.split("-")[1];

  const handleStyleVariant = () => {
    switch (splitVariant) {
      case "text":
        switch (splitColor) {
          case "neutral":
            return `text-${splitColor}-100
             focus:border-white-300
            bg-transparent focus:bg-white-300`;

          case "primary":
          case "danger":
          case "warning":
            return `text-${splitColor}-main hover:text-white-300 focus:text-white-100 active:text-white-400
             focus:border-${splitColor}-focus
            bg-transparent focus:bg-${splitColor}-focus`;

          default:
            return;
        }

      case "outlined":
        switch (splitColor) {
          case "primary":
          case "danger":
          case "warning":
            return `text-${splitColor}-main hover:text-${splitColor}-hover focus:text-${splitColor}-main active:text-${splitColor}-pressed
            border-2 border-${splitColor}-main hover:border-${splitColor}-hover focus:border-${splitColor}-focus active:border-${splitColor}-pressed
            bg-transparent
            focus:outline focus:outline-4 focus:outline-${splitColor}-focus`;

          default:
            return;
        }

      case "contained":
        switch (splitColor) {
          case "secondary":
            return `text-${splitColor}-main hover:text-white-100 focus:text-white-100 active:text-white-100 
            border-2 border-[#10C4A8] border-opacity-5 hover:border-${splitColor}-hover focus:border-${splitColor}-main active:border-${splitColor}-pressed
            bg-${splitColor}-focus hover:bg-${splitColor}-hover focus:bg-${splitColor}-main active:bg-${splitColor}-pressed 
            focus:outline focus:outline-4 focus:outline-${splitColor}-focus`;

          case "primary":
          case "danger":
          case "warning":
            return `text-white-100 
            border-2 border-${splitColor}-main hover:border-${splitColor}-hover focus:border-${splitColor}-main active:border-${splitColor}-pressed
            bg-${splitColor}-main hover:bg-${splitColor}-hover focus:bg-${splitColor}-main active:bg-${splitColor}-pressed 
            focus:outline focus:outline-4 focus:outline-${splitColor}-focus`;

          default:
            return;
        }

      default:
        return;
    }
  };

  const handleStyleDisable = () => {
    if (type === "reset") {
      return "bg-white-300 text-white-500 border-2 border-white-400";
    }

    if (disabled) {
      switch (splitVariant) {
        case "text":
          return "bg-transparent text-white-500 border-2 border-transparent";

        case "outlined":
        case "contained":
          return "bg-white-300 text-white-500 border-2 border-white-400";

        default:
          return;
      }
    } else {
      return handleStyleVariant();
    }
  };

  const handleComponentSize = () => {
    if (size === "xs") {
      return "px-2 py-1 text-xs";
    } else {
      return setComponentSize(size);
    }
  };

  return (
    <button
      type={type}
      disabled={disabled}
      className={`${handleComponentSize()} ${handleStyleDisable()} ${
        widthFull && "w-full"
      } ${cusomRounded} font-semibold capitalize ${addClassName}`}
      onClick={onClick}
    >
      <div className="flex items-center justify-center gap-2">
        {prependIcon}
        <div>{children}</div>
        {appendIcon}
      </div>
    </button>
  );
}

export default memo(Button);
