import useFilter from "@/store/useFilter";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

type Props = {};

const PageLoadingScreen = (props: Props) => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const [styles, api] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  useEffect(() => {
    const handleStart = (url: string) => {
      if (url !== router.asPath) {
        setLoading(true);
        api.start({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        });
      }
    };
    const handleComplete = (url: string) => {
      if (url === router.asPath) {
        api.start({
          from: {
            opacity: 1,
          },
          to: {
            opacity: 0,
          },
          onRest: () => setLoading(false),
        });
        return;
      }
      setLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  });

  return (
    <>
      {loading && (
        <animated.div
          style={styles}
          className="fixed inset-0 bg-neutral-400 z-50 flex-center"
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </animated.div>
      )}
    </>
  );
};

export default PageLoadingScreen;
