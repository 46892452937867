import Image from "next/image";
import { useRouter } from "next/router";
import React, { forwardRef, memo, useMemo } from "react";
import { TbLogout } from "react-icons/tb";
import Label from "@/components/atoms/asside/label";
import MenuBtn from "@/components/atoms/asside/menu-btn";
import Button from "@/components/atoms/Button";
import { useQueryClient } from "@tanstack/react-query";
import useAdminMe from "@/store/useAdminMe";
import useToken from "@/store/useToken";
import Link from "next/link";
import { MdModeEdit } from "react-icons/md";
import {
  masterListAdmin,
  masterList as masterSettingList,
  statusUserList,
  subCategoryList,
} from "@/plugins/GolekIklan";

const GokilAsside = ({ basis }: { basis: number }) => {
  const { asPath, pathname, push } = useRouter();
  const { adminMe } = useAdminMe();

  const { resetAdmin } = useAdminMe();
  const { setToken } = useToken();
  const queryClient = useQueryClient();

  const logout = () => {
    resetAdmin();
    queryClient.clear();
    setToken("");
    push("/login");
  };

  const golekIklanItemRouteList = useMemo(
    () =>
      subCategoryList["item"].children.map((subcategory) => ({
        label: subcategory.label,
        href: `/gokil/iklan?category=item&subCategory=${subcategory.value}`,
        active:
          asPath.includes(`/gokil/iklan`) &&
          asPath.includes(`category=item&subCategory=${subcategory.value}`),
      })),
    [asPath]
  );

  const masterSettingRouteList = useMemo(() => {
    if (adminMe?.role !== "super_admin") {
      return masterListAdmin.map((list) => ({
        label: list.label,
        href: `/gokil/setting/${list.value}`,
        active: asPath.includes(`/gokil/setting/${list.value}`),
      }));
    }
    return masterSettingList.map((list) => ({
      label: list.label,
      href: `/gokil/setting/${list.value}`,
      active: asPath.includes(`/gokil/setting/${list.value}`),
    }));
  }, [asPath]);

  const statusUserRouteList = useMemo(
    () =>
      statusUserList.map((list) => ({
        label: list.label,
        href: `/gokil/laporan/status-user/${list.value}`,
        active: asPath.includes(`/gokil/laporan/status-user/${list.value}`),
      })),
    [asPath]
  );

  const golekIklanServiceRouteList = useMemo(
    () =>
      subCategoryList["service"].children.map((subcategory) => ({
        label: subcategory.label,
        href: `/gokil/iklan?category=service&subCategory=${subcategory.value}`,
        active:
          asPath.includes(`/gokil/iklan`) &&
          asPath.includes(`category=service&subCategory=${subcategory.value}`),
      })),
    [asPath]
  );

  return (
    <div
      className="asside mb-10 shrink-0 grow basis-3/12 overflow-auto p-6"
      style={
        basis
          ? {
              flexBasis: basis,
            }
          : {}
      }
    >
      <div className="flex flex-col gap-6">
        {/* avatar */}
        <div className="flex flex-col items-center gap-3">
          <div className="relative h-20 w-20">
            <Image src={"/icon/avatar.svg"} alt="avatar" fill />
          </div>
          <p className="text-lg font-bold">{adminMe?.name}</p>
          <Link
            href={`/gokil/admin/edit/${adminMe?.id}`}
            className="flex cursor-pointer items-center gap-2 font-semibold text-primary-main hover:text-primary-hover"
          >
            <MdModeEdit />
            Ubah Profil
          </Link>
        </div>
        {/* end avatar */}

        {/* -------------------------------------------------------------------------- */
        /*                                 menu utama                                 */
        /* -------------------------------------------------------------------------- */}
        <div className="flex flex-col gap-1">
          <Label label="menu utama" />
          <MenuBtn
            icon="/icon/home-icon.svg"
            label="Dashboard"
            active={asPath === "/gokil"}
            href="/gokil"
          />
        </div>

        <div className="flex flex-col gap-1">
          <Label label="Laporan" />
          {/* <MenuBtn
            icon='/icon/ads-shipper.svg'
            label='Deal Iklan'
            href='/gokil/laporan/deal-iklan'
            active={asPath.includes('/gokil/deal-iklan')}
          /> */}
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Laporan Riwayat Pembelian Kuota"
            active={asPath.includes(
              "/gokil/laporan/laporan-riwayat-pembelian-kuota"
            )}
            href="/gokil/laporan/laporan-riwayat-pembelian-kuota"
          />
          {/* grafana */}
          {/* <MenuBtn
            icon='/icon/ads-trucker.svg'
            label='Pembelian Iklan Sundul'
            active={asPath == '/gokil/laporan/pembelian-iklan-sundul'}
            href='/gokil/laporan/pembelian-iklan-sundul'
          /> */}
          {/* <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Akun Blokir Akun"
            active={asPath == "/gokil/laporan/akun-blokir-akun"}
            href="/gokil/laporan/akun-blokir-akun"
          /> */}
          {/* grafana */}
          {/* <MenuBtn
            icon='/icon/ads-shipper.svg'
            label='Status User'
            active={
              asPath.includes('/gokil/laporan/status-user') &&
              asPath.includes('category=item')
            }
            dropdown={statusUserRouteList}
          /> */}
        </div>

        {/*
                ⠀⠀⠀⠀⠀⠀⠀⢀⡀⠴⠤⠤⠴⠄⡄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠀⣠⠄⠒⠉⠀⠀⠀⠀⠀⠀⠀⠀⠁⠃⠆⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⢀⡜⠁⠀⠀⠀⢠⡄⠀⣀⠀⠀⠀⠀⠀⠀⠀⠀⠑⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⢈⠁⠀⠀⠠⣿⠿⡟⣀⡹⠆⡿⣃⣰⣆⣤⣀⠀⠀⠹⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⣼⠀⠀⢀⣀⣀⣀⣀⡈⠁⠙⠁⠘⠃⠡⠽⡵⢚⠱⠂⠛⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⡆⠀⠀⠀⠀⢐⣢⣤⣵⡄⢀⠀⢀⢈⣉⠉⠉⠒⠤⠀⠿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠘⡇⠀⠀⠀⠀⠀⠉⠉⠁⠁⠈⠀⠸⢖⣿⣿⣷⠀⠀⢰⡆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀Iklan⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⡇⠀⠀⠀⠀⠀⠀⠀⠀⢀⠃⠀⡄⠀⠈⠉⠀⠀⠀⢴⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⢈⣇⠀⠀⠀⠀⠀⠀⠀⢰⠉⠀⠀⠱⠀⠀⠀⠀⠀⢠⡄⠀⠀⠀⠀⠀⣀⠔⠒⢒⡩⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⣴⣿⣤⢀⠀⠀⠀⠀⠀⠈⠓⠒⠢⠔⠀⠀⠀⠀⠀⣶⠤⠄⠒⠒⠉⠁⠀⠀⠀⢸⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⡄⠤⠒⠈⠈⣿⣿⣽⣦⠀⢀⢀⠰⢰⣀⣲⣿⡐⣤⠀⠀⢠⡾⠃⠀⠀⠀⠀⠀⠀⠀⣀⡄⣠⣵⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠘⠏⢿⣿⡁⢐⠶⠈⣰⣿⣿⣿⣿⣷⢈⣣⢰⡞⠀⠀⠀⠀⠀⠀⢀⡴⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠈⢿⣿⣍⠀⠀⠸⣿⣿⣿⣿⠃⢈⣿⡎⠁⠀⠀⠀⠀⣠⠞⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠀⠈⢙⣿⣆⠀⠀⠈⠛⠛⢋⢰⡼⠁⠁⠀⠀⠀⢀⠔⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠚⣷⣧⣷⣤⡶⠎⠛⠁⠀⠀⠀⢀⡤⠊⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠁⠈⠁⠀⠀⠀⠀⠀⠠⠊⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⠁⠀⠀⠀⠀⠀⠀⠀
        
        */}
        <div className="flex flex-col gap-1">
          <Label label="Iklan" />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="Iklan Barang"
            active={
              asPath.includes("/gokil/iklan") &&
              asPath.includes("category=item")
            }
            dropdown={golekIklanItemRouteList}
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Iklan Jasa"
            active={
              asPath.includes("/gokil/iklan") &&
              asPath.includes("category=service")
            }
            dropdown={golekIklanServiceRouteList}
          />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="Posting Iklan Barang"
            href="/gokil/posting?category=item"
            active={
              asPath.includes("/gokil/posting") &&
              asPath.includes("category=item")
            }
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Posting Iklan Jasa"
            href="/gokil/posting?category=service"
            active={
              asPath.includes("/gokil/posting") &&
              asPath.includes("category=service")
            }
          />
        </div>

        {/* -------------------------------------------------------------------------- */
        /*                                   Master                                   */
        /* -------------------------------------------------------------------------- */}
        <div className="flex flex-col gap-1">
          <Label label="Master" />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="Master Setting"
            active={
              asPath.includes("/gokil/setting") &&
              asPath.includes("category=item")
            }
            dropdown={masterSettingRouteList}
          />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="Master Admin"
            href="/gokil/master/admin"
            active={pathname.includes("/gokil/master/admin")}
          />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="Banner Promo"
            href="/gokil/master/banner-promo"
            active={pathname.includes("/gokil/master/banner-promo")}
          />
        </div>

        {adminMe?.role == "super_admin" && (
          <div className="flex flex-col gap-1">
            <Label label="Golek Koin" />
            <MenuBtn
              icon="/icon/master-trucker.svg"
              label="setting Hadiah"
              active={pathname == "/gokil/koin/hadiah"}
              href="/gokil/koin/hadiah?tab=koin-share-iklan"
            />
          </div>
        )}

        {/* Mass Upload */}
        <div className="flex flex-col gap-1">
          <Label label="Mass Upload" />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Mass Upload Iklan"
            active={asPath == "/bulk-ads"}
            dropdown={[
              { label: "Barang", href: "/gokil/mass-upload/item" },
              { label: "Jasa", href: "/gokil/mass-upload/service" },
            ]}
          />
        </div>

        <div className="flex flex-col gap-1">
          <Label label="master data target" />
          <MenuBtn
            icon="/icon/master-data-target.svg"
            label="master data target"
            href="/gokil/master/data-target"
            active={asPath == "/gokil/master/data-target"}
          />
        </div>

        <Button
          prependIcon={<TbLogout className="text-xl" />}
          onClick={() => logout()}
          color="danger-contained"
        >
          Logout
        </Button>
        {/* end menu */}
      </div>
    </div>
  );
};

export default memo(GokilAsside);
