import { Size } from "types/Style";

/**
 * changes component's padding and font size.
 *
 * @param {Size} size - accepts "sm" | "md" | "lg".
 */
const setComponentSize = (size: Size): string | void => {
  switch (size) {
    case "sm": return "px-3 py-2 text-sm";
    case "md": return "px-4 py-3 text-base";
    case "lg": return "p-4 text-lg";
    default: return;
  }
}

export default setComponentSize;