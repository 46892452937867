import useClickOutside from "@/hooks/useClickOutside";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

const ServiceNav = () => {
    const [isShow, setIsShow] = useState(false)
    const ref = useRef<HTMLDivElement>(null);
    const router = useRouter()
  
    useClickOutside(ref, () => isShow && setIsShow(false));
  
    return (
      <div className="relative">
  
        <div 
          className="flex items-center gap-2 cursor-pointer" 
          ref={ref} 
          onClick={() => setIsShow(true)}
        >
          <p className="text-white-0 font-medium text-sm">
            {router.asPath.match(/gokil/) ? "GolekIklan" : "Shipper dan Trucker"}
          </p>
          <MdArrowDropDown className="text-white-100 text-2xl" />
        </div>
  
        {
          isShow && (
            <div className="absolute z-50 bg-neutral-200 w-[234px] shadow rounded-lg top-[30px] -right-[16px]">
              <List type="GolekTruk" />
              <List type="GolekIklan" />
            </div>
          )
        }
  
      </div>
    )
}
  
const List = ({type}:{type: "GolekTruk" | "GolekIklan"}) => {
const router = useRouter()

return (
    <div 
    className={`text-sm font-semibold mx-2 my-4 cursor-pointer ${(router.asPath.match(/gokil/) && type === "GolekIklan") ? "text-secondary-main" : (!router.asPath.match(/gokil/) && type === "GolekTruk") ? "text-secondary-main" : "text-white-100"}`} 
    onClick={() => router.push(`${type === "GolekIklan" ? "/gokil" : "/"}`)}
    >
    {type === "GolekIklan" ? "GolekIklan" : "Shipper dan Trucker" }
    </div>
)
}

export default ServiceNav