import { create } from "zustand";
import { persist } from "zustand/middleware";

/**
 * token untuk header bearer axios
 */

type useTokenType = {
  token: string;
  setToken: (token: string) => void;
};

const useToken = create(
  persist<useTokenType>(
    (set) => ({
      token: "",
      setToken: (token: string) => set(() => ({ token })),
    }),
    {
      name: "myTokenStore",
      getStorage: () => localStorage,
    }
  )
);

export default useToken;
