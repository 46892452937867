import { create } from "zustand";

interface LoadingState {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

/**
 * Utility loading useful for showing loading on fetch or send data
 *
 * !Be careful with the usage or you might end up with infinite loading
 */
const useLoading = create<LoadingState>((set) => ({
  isLoading: false,
  showLoading: () => set({ isLoading: true }),
  hideLoading: () => set({ isLoading: false }),
}));

export default useLoading;
