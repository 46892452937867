import { Category, SubCategory } from "@/types/GolekIklan/GolekIklan";

interface subCategoryListType {
  name: string;
  children: SubCategoryOptionType[];
}

export interface SubCategoryOptionType {
  value: string;
  label: string;
}

// Pake buat option, etc
export const categoryList = [
  { value: "item", label: "Barang" },
  { value: "service", label: "Jasa" },
  // TODO: uncomment later
  // ! DO NOT DELETE
  // { value: "promo", label: "Promo" },
  // { value: "property", label: "Properti" },
  // { value: "job_listing", label: "Lowongan Kerja" },
  // { value: "company_profile", label: "Perusahaan" },
];

// Pake buat option, etc
export const subCategoryList: { [key: string]: subCategoryListType } = {
  item: {
    name: "Barang",
    children: [
      { value: "tire", label: "Ban" },
      { value: "velg", label: "Velg" },
      { value: "lubricant", label: "Oli" },
      { value: "accu", label: "Aki" },
      { value: "container", label: "Kontainer" },
      { value: "truck", label: "Truk Baru dan Bekas" },
      { value: "tools", label: "Perlengkapan Angkutan" },
      { value: "spare_part", label: "Suku Cadang(sparepart)" },
      { value: "karoseri_item", label: "Produk Karoseri" },
      { value: "other_item", label: "Barang Lainnya" },
    ],
  },
  service: {
    name: "Iklan Jasa",
    children: [
      { value: "karoseri_service", label: "Karoseri" },
      { value: "mechanic", label: "Mekanik / Teknisi" },
      { value: "autoshop", label: "Bengkel" },
      { value: "finance", label: "Keuangan" },
      { value: "insurance", label: "Asuransi" },
      { value: "warehouse", label: "Pergudangan" },
      { value: "other_service", label: "Jasa Lainnya" },
    ],
  },
  promo: {
    name: "Promo",
    children: [],
  },
  property: {
    name: "Properti",
    children: [],
  },
  job_listing: {
    name: "Lowongan Kerja",
    children: [],
  },
  company_profile: {
    name: "Company Profile",
    children: [],
  },
};

/**
 *
 * @param value string
 * @param valueToCompare string from list or array
 * @returns
 */
export const compareCategory = (value: unknown, valueToCompare: Category | Category[]) => {
  if (typeof valueToCompare === "string") return value === valueToCompare;
  return valueToCompare.some((e) => e === value);
};

/**
 *
 * @param value string
 * @param valueToCompare string from list or array
 * @returns
 */
export const compareSubCategory = (value: unknown, valueToCompare: SubCategory | SubCategory[]) => {
  if (typeof valueToCompare === "string") return value === valueToCompare;
  return valueToCompare.some((e) => e === value);
};

/**
 * @example CategoryLookup["item"] would return "Barang"
 */
export const CategoryLookup: { [key: string]: string } = {
  item: "Barang",
  service: "Jasa",
  promo: "Promo",
  property: "Properti",
  job_listing: "Lowongan Kerja",
  company_profile: "Perusahaan",
};

/**
 * @example SubCategoryLookup["tire"] would return "Ban"
 */
export const SubCategoryLookup: { [key: string]: string } = {
  // Item / Barang
  tire: "Ban",
  velg: "Velg",
  lubricant: "Oli",
  accu: "Aki",
  container: "Kontainer",
  truck: "Truk Baru dan Bekas",
  tools: "Perlengkapan Angkutan",
  spare_part: "Suku Cadang(sparepart)",
  karoseri_item: "Produk Karoseri",
  other_item: "Barang Lainnya",

  // Service / Jasa
  karoseri_service: "Karoseri",
  mechanic: "Mekanik / Teknisi",
  autoshop: "Bengkel",
  finance: "Keuangan",
  insurance: "Asuransi",
  warehouse: "Pergudangan",
  other_service: "Jasa Lainnya",
};

export const masterList = [
  {
    value: "durasi-kuota-highlight",
    label: "Setting Harga & Durasi Iklan Highlight",
  },
  {
    value: "durasi-kuota-iklan",
    label: "Setting Harga & Durasi Kuota Iklan",
  },
  {
    value: "harga-iklan-sundul",
    label: "Setting Harga Iklan Sundul",
  },
  {
    value: "promo-kuota-iklan",
    label: "Setting Promo Kuota Iklan",
  },
];

export const masterListAdmin = [
  {
    value: "promo-kuota-iklan",
    label: "Setting Promo Kuota Iklan",
  },
];

export const statusUserList = [
  {
    value: "sudah-punya-kuota-iklan",
    label: "Sudah Punya Kuota Iklan",
  },
  {
    value: "belum-punya-kuota-iklan",
    label: "Belum Punya Kuota Iklan",
  },
  {
    value: "sudah-punya-kuota-highlight",
    label: "Sudah Punya Kuota Iklan Highlight",
  },
  {
    value: "belum-punya-kuota-highlight",
    label: "Belum Punya Kuota Iklan Highlight",
  },
  {
    value: "belum-posting-iklan",
    label: "Belum Posting Iklan",
  },
  {
    value: "belum-punya-kuota-highlight",
    label: "Sudah Posting Iklan",
  },
];

export const optionsCategoryProduct = [
  { label: "Ban", value: "tire" },
  { label: "Velg", value: "velg" },
  { label: "Oli", value: "lubricant" },
  { label: "Aki", value: "accu" },
  { label: "Truk Baru & Bekas", value: "truck" },
  { label: "Perlengkapan Angkutan", value: "tools" },
  { label: "Suku Cadang", value: "spare_part" },
  { label: "Produk Karoseri", value: "karoseri_item" },
  { label: "Lainnya", value: "other_item" },
];

export const optionsCategoryProductWithAll = [{ label: "Semua", value: "ALL" }, ...optionsCategoryProduct];

export const optionsCategoryService = [
  { label: "Karoseri", value: "karoseri_service" },
  { label: "Mekanik / Teknisi", value: "mechanic" },
  { label: "Bengkel", value: "autoshop" },
  { label: "Keuangan", value: "finance" },
  { label: "Asuransi", value: "insurance" },
  { label: "Pergudangan", value: "warehouse" },
  { label: "Lainnya", value: "other_service" },
];

export const optionsCategoryServiceWithAll = [{ label: "Semua", value: "ALL" }, ...optionsCategoryService];

export const optionsCondition = [
  { label: "Semua", value: "" },
  { label: "Baru", value: "new" },
  { label: "Bekas", value: "old" },
];

// mass upload document

export const documentFileName: { [key: string]: Record<string, string> } = {
  item: {
    tire: "/document/item/template_mass_upload_iklan_ban_excel.xlsx",
    velg: "/document/item/template_mass_upload_iklan_velg_excel.xlsx",
    lubricant: "/document/item/template_mass_upload_iklan_oli_excel.xlsx",
    accu: "/document/item/template_mass_upload_iklan_aki_excel.xlsx",
    container: "/document/item/template_mass_upload_iklan_kontainer_excel.xlsx",
    truck: "/document/item/template_mass_upload_iklan_truk baru dan bekas_excel.xlsx",
    tools: "/document/item/template_mass_upload_iklan_perlengkapan angkutan_excel.xlsx",
    spare_part: "/document/item/template_mass_upload_iklan_suku cadang_excel.xlsx",
    karoseri_item: "/document/item/template_mass_upload_iklan_produk karoseri_excel.xlsx",
    other_item: "/document/item/template_mass_upload_iklan_barang lainnya_excel.xlsx",
  },
  // /document/jasa/
  service: {
    karoseri_service: "/document/jasa/template_mass_upload_iklan_jasa karoseri_excel.xlsx",
    mechanic: "/document/jasa/template_mass_upload_iklan_jasa mekanik-teknisi_excel.xlsx",
    autoshop: "/document/jasa/template_mass_upload_iklan_jasa bengkel_excel.xlsx",
    finance: "/document/jasa/template_mass_upload_iklan_jasa keuangan_excel.xlsx",
    insurance: "/document/jasa/template_mass_upload_iklan_jasa asuransi_excel.xlsx",
    warehouse: "/document/jasa/template_mass_upload_iklan_jasa pergudangan_excel.xlsx",
    other_service: "/document/jasa/template_mass_upload_iklan_jasa lainnya_excel.xlsx",
  },
};
