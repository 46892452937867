import useScrollPositions from '@/store/useScrollPositions'
import { useRouter } from 'next/router'
import { LegacyRef, Ref, RefObject, useEffect, useRef } from 'react'

export const usePreserveTableScroll = (
  pathnameException: string[]
) => {
  const router = useRouter()

  // const scrollPositions = useRef<{ [url: string]: number }>({})
  // const isBack = useRef(false)


  const {scrollPositions, addScrollPositions, removeScrollPositions} = useScrollPositions()


  useEffect(() => {
    console.log("scpos", scrollPositions)

    const onRouteChangeStart = () => {
      const url = router.asPath
      let tableEl = document.getElementById("tablewrapper");
      if(!tableEl || tableEl.scrollTop === undefined)return
      console.log("what table start", tableEl.scrollTop)
      addScrollPositions(url,tableEl.scrollTop)
      }
      
      const onRouteChangeComplete = (url: any) => {
      if (scrollPositions[url]) {
        if (pathnameException.some((path) => path === router.asPath)) return
        
        let tries = 1
        let tableEl = document.getElementById("tablewrapper");

        let intr = setInterval(() => {
          
          console.log("what table tries", tableEl, tries)
          if (tries > 50) {
            clearInterval(intr);
            return
          }
          tableEl = document.getElementById("tablewrapper");
          console.log("what table", tableEl)
          if (!tableEl) {
            tries++;  
            return
          }

          setTimeout(() => {
            tableEl!.scroll({
              top: scrollPositions[url],
              behavior: 'auto',
            })
          },500)
          
          clearInterval(intr);
        },200)

      }
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router, scrollPositions])
}
