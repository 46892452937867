import "../styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import type { AppProps } from "next/app";
import Head from "next/head";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Template from "@/components/templates/layout";
import { useRouter } from "next/router";
import PageLoadingScreen from "@/components/organisms/PageLoadingScreen";
import useMenuLoading from "@/store/loading";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import LoadingScreen from "@/components/organisms/LoadingScreen";

import "swiper/css";
import "swiper/css/pagination";
import useFilter from "@/store/useFilter";
import { usePreserveScroll } from "@/hooks/usePreserveScroll";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const { pathname, push } = useRouter();
  const interceptRouter = ["/404", "/login"];
  const { filter } = useFilter();

  // console.log("🚍Filter", filter);

  return (
    <>
      <Head>
        <title>NextGolek | Admin 2023</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <LoadingScreen />
        <PageLoadingScreen />
        <Template intercept={interceptRouter.includes(pathname)}>
          <Component {...pageProps} />
        </Template>
        <ToastContainer theme="dark" />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
