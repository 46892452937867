import { create } from "zustand";

interface Pos{[key:string]:number}


interface ScrollPosition {
  scrollPositions: Pos;
  addScrollPositions: (url:string, pos:number) => void;
  removeScrollPositions: (url:string) => void;
  resetScrollPositions: () => void;
}

const useScrollPositions = create<ScrollPosition>((set) => ({
  scrollPositions: {},
  addScrollPositions: (url, pos) => set(state => {

    return ({...state, scrollPositions:{...state.scrollPositions, [url]:pos}})
  }),
  removeScrollPositions: (url) => set(state => {
    let newScrollPos = state.scrollPositions

    delete newScrollPos[url]

    return ({...state, scrollPositions: newScrollPos})
  }),
  resetScrollPositions: () => set(state => ({...state, scrollPositions:{}}))
}));

export default useScrollPositions;
