import  {create}  from "zustand";

/**
 * set admin me
 */

export type adminMeType = {
  active: boolean;
  create_at: Date;
  create_by_id: number;
  delete_at: null | Date;
  delete_by_id: null | number;
  email: string;
  id: number;
  name: string;
  role: string;
  update_at: null | Date;
  update_by_id: null | Date;
} | null;

type status = "NOT FETCH" | "NOT FOUND" | "HAVE ADMIN ME";

type useAdminMeType = {
  adminMe: adminMeType;
  statusAdmin: status;
  setAdminMe: (adminMe: adminMeType) => void;
  setStatusAdmin: (status: status) => void;
  resetAdmin: () => void;
};

const useAdminMe = create<useAdminMeType>(
    (set) => ({
      adminMe: null,
      statusAdmin: "NOT FETCH",
      setAdminMe: (adminMe:adminMeType) => set(() => ({ adminMe })),
      setStatusAdmin: (statusAdmin:status) => set(() => ({ statusAdmin })),
      resetAdmin: () => set((state) => ({ 
        adminMe: null, 
        statusAdmin: "NOT FETCH" 
      })),
    })
);

export default useAdminMe;
