import useLoading from "@/store/loading";
import React from "react";

type Props = {};

const LoadingScreen = (props: Props) => {
  const isLoading = useLoading((state) => state.isLoading);
  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-neutral-400 z-50 flex-center">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingScreen;
