import { useRouter } from 'next/router'
import { LegacyRef, Ref, RefObject, useEffect, useRef } from 'react'

export const usePreserveScroll = (
  watchRef: RefObject<HTMLDivElement>,
  pathnameException: string[]
) => {
  const router = useRouter()

  const scrollPositions = useRef<{ [url: string]: number }>({})
  const isBack = useRef(false)

  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true
      return true
    })

    const onRouteChangeStart = () => {
      const url = router.pathname
      scrollPositions.current[url] = watchRef.current?.scrollTop ?? 0
    }

    const onRouteChangeComplete = (url: any) => {
      if (isBack.current && scrollPositions.current[url]) {
        if (pathnameException.some((path) => path === router.pathname)) return

        setTimeout(() => {
          if (!watchRef || watchRef.current === null) return

          watchRef.current?.scroll({
            top: scrollPositions.current[url],
            behavior: 'auto',
          })
        }, 100)
      }

      isBack.current = false
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router, watchRef])
}
